import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue';
import Index from '@/views/Index.vue'; // 假设你有一个 Index.vue 组件
Vue.use(VueRouter)

const routes = [

  // 登录都可以访问
  {
    path: '/',
    component: Login,

  },


  // 登录后的主页 需要判断是否登录成功

  {
    path: '/index',
    component: Index,

    children: [

      { path: '/views', component: () => import('@/views/Welcome.vue') },
      //老用户版本控制
      { path: '/views/appVersion/AppVersionVue', component: () => import('@/views/appVersion/AppVersion.vue') },
      // 反馈
      { path: '/views/feedback/FeedbackVue', component: () => import('@/views/feedback/Feedback.vue') },
      // 金币
      { path: '/views/gold/GoleVue', component: () => import('@/views/gold/Gold.vue') },
      // 热搜推荐
      { path: '/views/hitVideo/HitVideoVue', component: () => import('@/views/hitVideo/HitVideo.vue') },
      { path: '/views/hitVideo/InsertHitVideoVue', component: () => import('@/views/hitVideo/InsertHitVideo.vue') },
      { path: '/views/hitVideo/UpdateHitVideoVue', component: () => import('@/views/hitVideo/UpdateHitVideo.vue') },
      // 新用户版本控制
      { path: '/views/newAppVersion/NewAppVersionVue', component: () => import('@/views/newAppVersion/newAppVersion.vue') },
      { path: '/views/newAppVersion/InsertNewAppVersionVue', component: () => import('@/views/newAppVersion/InsertNewAppVersion.vue') },
      { path: '/views/newAppVersion/UpdateNewAppVersionVue', component: () => import('@/views/newAppVersion/UpdateNewAppVersion.vue') },
      // 提现
      { path: '/views/payouts/PayoutsVue', component: () => import('@/views/payouts/Payouts.vue') },
      // 提现审核
      { path: '/views/payouts/WithdrawalVue', component: () => import('@/views/payouts/Withdrawal.vue') },
      // 用户
      { path: '/views/user/UserVue', component: () => import('@/views/user/User.vue') },
      { path: '/views/user/UpdateUserVue', component: () => import('@/views/user/UpdateUser.vue') },
      // 短剧
      { path: '/views/video/VideoVue', component: () => import('@/views/video/Video.vue') },
      { path: '/views/video/InsertVideoVue', component: () => import('@/views/video/InsertVideo.vue') },
      { path: '/views/video/UpdateVideoVue', component: () => import('@/views/video/UpdateVideo.vue') },
      // vip
      { path: '/views/vip/VipVue', component: () => import('@/views/vip/Vip.vue') },
      { path: '/views/vip/InsertVipVue', component: () => import('@/views/vip/InsertVip.vue') },
      { path: '/views/vip/UpdateVipVue', component: () => import('@/views/vip/UpdateVip.vue') },
      // vip记录
      { path: '/views/vipEcord/vipEcordVue', component: () => import('@/views/vipEcord/VipEcord.vue') },
      // 必应规则
      { path: '/views/bing/BingVue', component: () => import('@/views/bing/Bing.vue') },
      // 必应新增
      { path: '/views/bing/InsertBingVue', component: () => import('@/views/bing/InsertBing.vue') },
      // 必应修改
      { path: '/views/bing/UpdateBingVue', component: () => import('@/views/bing/UpdateBing.vue') },
      { path: '/views/bing/UpdateBingVue', component: () => import('@/views/bing/UpdateBing.vue') },
      // 片单管理
      { path: '/views/slice/SliceVue', component: () => import('@/views/slice/Slice.vue') },
      // 片单新增
      { path: '/views/slice/InsertSliceVue', component: () => import('@/views/slice/InsertSlice.vue') },
      // 片单编辑
      { path: '/views/slice/UpdateSliceVue', component: () => import('@/views/slice/UpdateSlice.vue') },
      // 片单详情-影视页面
      { path: '/views/slice/SliceDetailVue', component: () => import('@/views/slice/SliceDetail.vue') },
      // 片单详情-影视编辑
      { path: '/views/slice/UpdateSliceDetailVue', component: () => import('@/views/slice/UpdateSliceDetail.vue') },
      // 片单详情-影视新增
      { path: '/views/slice/InsertSliceDetailVue', component: () => import('@/views/slice/InsertSliceDetail.vue') },
      // 规则链接
      { path: '/views/rulesurl/RulesurlVue', component: () => import('@/views/rulesurl/Rulesurl.vue') },
      // 规则链接新增
      { path: '/views/rulesurl/InsertRulesurlVue', component: () => import('@/views/rulesurl/InsertRulesurl.vue') },
      // 规则链接修改
      { path: '/views/rulesurl/UpdateRulesurlVue', component: () => import('@/views/rulesurl/UpdateRulesurl.vue') },
      // 搜索统计
      { path: '/views/search/searchVue', component: () => import('@/views/search/search.vue') },
      // 违禁词查询
      { path: '/views/searchSensitive/SearchSensitiveVue', component: () => import('@/views/searchSensitive/SearchSensitive') },
      // 违禁词修改
      { path: '/views/searchSensitive/UpdateSearchSensitiveVue', component: () => import('@/views/searchSensitive/UpdateSearchSensitive') },

    ]
  },
  { path: '/', component: Index },
]


//原始代码
const router = new VueRouter({
  routes
})


export default router
