<template>
  <el-container class="index">
    <el-aside width="200px">
      <el-menu router :default-active="$store.state.activeMenu" background-color="#545c64" text-color="#fff"
        active-text-color="#ffd04b" @select="onSelectAsideMenu">
        <el-menu-item index="/views">
          <i class="el-icon-menu"></i>
          <span slot="title">首页</span>
        </el-menu-item>

        <el-menu-item index="/views/user/UserVue">
          <i class="el-icon-user-solid"></i>
          <span>用户管理</span>
        </el-menu-item>

        <el-menu-item index="/views/vip/VipVue">
          <i class="el-icon-coin"></i>
          <span>VIP管理</span>
        </el-menu-item>

        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-s-order"></i>
            <span>数据记录</span>
          </template>
          <el-menu-item-group>

            <el-menu-item index="/views/vipEcord/vipEcordVue">
              <i class="el-icon-s-claim"></i>
              <span>充值记录</span>
            </el-menu-item>

            <el-menu-item index="/views/payouts/PayoutsVue">
              <i class="el-icon-wallet"></i>
              <span>提现记录</span>
            </el-menu-item>

            <el-menu-item index="/views/gold/GoleVue">
              <i class="el-icon-pie-chart"></i>
              <span>金币记录</span>
            </el-menu-item>

            <el-menu-item index="/views/payouts/WithdrawalVue">
              <i class="el-icon-s-check"></i>
              <span>大额审核</span>
            </el-menu-item>

          </el-menu-item-group>
        </el-submenu>

        <!--新老用户版本控制 -->
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-operation"></i>
            <span>版本控制</span>
          </template>
          <el-menu-item-group>
            <!-- 新用户 -->
            <el-menu-item index="/views/newappVersion/NewAppVersionVue">
              <i class="el-icon-user"></i>
              <span>新用户广告位</span>
            </el-menu-item>
            <!-- 老用户 -->
            <el-menu-item index="/views/appVersion/AppVersionVue">
              <i class="el-icon-user"></i>
              <span>老用户广告位</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>


        <!-- 2 -->
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-video-camera"></i>
            <span>影视控制</span>
          </template>
          <el-menu-item-group>

            <el-menu-item index="/views/slice/SliceVue">
              <i class="el-icon-s-ticket"></i>
              <span>片单管理</span>
            </el-menu-item>

            <el-menu-item index="/views/video/VideoVue">
              <i class="el-icon-s-data"></i>
              <span>短剧管理</span>
            </el-menu-item>

            <el-menu-item index="/views/hitVideo/HitVideoVue">
              <i class="el-icon-collection-tag"></i>
              <span>热搜推荐</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>


        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-share"></i>
            <span>必应链接</span>
          </template>
          <el-menu-item-group>

            <el-menu-item index="/views/bing/BingVue">
              <i class="el-icon-news"></i>
              <span>必应规则</span>
            </el-menu-item>

            <el-menu-item index="/views/rulesurl/RulesurlVue">
              <i class="el-icon-paperclip"></i>
              <span>规则链接</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        
        <el-menu-item index="/views/search/searchVue">
          <i class="el-icon-pie-chart"></i>
          <span>搜索统计</span>
        </el-menu-item>

        <el-menu-item index="/views/searchSensitive/SearchSensitiveVue">
          <i class="el-icon-tickets"></i>
          <span>违禁词汇</span>
        </el-menu-item>

        <el-menu-item index="/views/feedback/FeedbackVue">
          <i class="el-icon-data-line"></i>
          <span>反馈管理</span>
        </el-menu-item>

      </el-menu>
    </el-aside>
    <el-container>
      <el-header>

        <el-row type="flex" jusitfy="between">
          <el-col>
            <h1 class="logo">后台管理</h1>
          </el-col>
          <el-col :span="4" class="avatar-container">
            <!-- 图片地址 -->
            <el-avatar
              src="https://img2.baidu.com/it/u=3150725394,4121447528&fm=253&fmt=auto&app=138&f=JPEG?w=474&h=475"></el-avatar>
          </el-col>

          <el-col>
            <el-menu class="el-menu-demo" mode="horizontal" background-color="#545c64" text-color="#fff"
              active-text-color="#ffd04b">
              <el-submenu index="2">
                <template slot="title">{{ $store.getters.nickname }}</template>
                <el-menu-item index="2-1">身份：管理员</el-menu-item>
                <el-menu-item index="/" @click="logout">退出登录</el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>




      </el-header>
      <el-main>
        <RouterView />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

export default {

  data() {
    return {

      vif: false,
      value: '2',
      // 记录当前数据条
      dto: '',
    };
  },
  // 页面加载时访问数据库
  mounted() {

  },

  methods: {

    logout() {
      sessionStorage.clear();
      this.$message.success('退出成功');
      this.$router.push('/');
    },
    onSelectAsideMenu(index) {
      this.$store.commit('setActiveMenu', index);
    }
  }
};
</script>

<style scoped>
.item {
  margin-top: 10px;
  margin-right: 30px;
}

.index {
  height: 100vh;
}

.el-aside {
  background-color: #545c64;
}

.el-menu {
  border-right: none;
}

.el-header {
  padding: 0;
  background-color: #545c64;
}

.logo {
  color: #fff;
  font-size: 1.5rem;
  line-height: 60px;
  margin: 0;
  padding: 0 1rem;
}

.el-col .el-menu {
  float: right;
}


.avatar-container {
  position: absolute;
  top: 10px;
  /* 向上移动，留出空间 */
  right: 10px;
  /* 向右移动，留出空间 */

  /* 设定一个具体的宽度和高度（可选） */
  width: 100px;
  /* 或您需要的任何宽度 */
  height: 100px;
  /* 如果需要的话，也可以设置高度 */

}

.menu-icon {
  width: 20px;
  /* 根据你的图标大小调整 */
  height: 20px;
  /* 根据你的图标大小调整 */
  margin-right: 8px;
  /* 调整图标和文本之间的间距 */
}
</style>
